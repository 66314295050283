.search {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
}

.result {
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
}
.error {
    position: absolute;
    left: 50%;
    top: 57%;
    transform: translate(-50%, -50%);
}

.ant-result-subtitle {
    font-size: 1.5em !important;
}

.ant-result-extra {
    font-size: 1em !important;
}
